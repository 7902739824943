import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Book } from '../../../models/Book';
import BookCard from '../../book/BookCard';
import ContainerPageNavigation from '../../shared/ContainerPageNavigation';
import Grid from '@mui/joy/Grid';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import {fetchCurrentBooks} from "../../../api_calls/api";

interface CurrentReadingProps {}

const CurrentReading: React.FC<CurrentReadingProps> = () => {
    const [books, setBooks] = useState<Book[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 5;

    useEffect(() => {
        const fetchBooks = async (page: number, per_page: number) => {
            try {
                const data = await fetchCurrentBooks(currentPage, itemsPerPage);
            if (data) {
                // setBooks(response.data.data);
                setBooks(data);
            }

            } catch (error) {
                console.error('Error fetching books:', error);
            }
        };

        fetchBooks(currentPage, itemsPerPage);
    }, [currentPage, itemsPerPage]);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Stack alignItems="center">
            <ContainerPageNavigation
                page={currentPage}
                totalPages={Math.ceil(books.length / itemsPerPage)}
                perPage={itemsPerPage}
                onPageChange={setCurrentPage}
                text="Continue Reading"
            />
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                sx={{ width: '80%', mt: 0, mb: 0 }}
            >
                {books.map((book) => (
                    <Tooltip
                        key={book.book_id} // Add a key prop here to avoid React warning
                        title={
                            <Card variant="solid" sx={{ width: '240px' }}>
                                <CardContent>
                                    <Typography level="title-md" textColor="inherit">
                                        {book.title}
                                    </Typography>
                                    <Typography textColor="inherit">{book.description}</Typography>
                                </CardContent>
                            </Card>
                        }
                        arrow
                        placement="right"
                    >
                        <Grid container>
                            <BookCard book={book} />
                        </Grid>
                    </Tooltip>
                ))}
            </Grid>
        </Stack>
    );
};

export default CurrentReading;
