import React from "react";
import Typography from '@mui/joy/Typography';
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import TextLoop from "react-text-loop";
import monster from "../assets/images/godzilla.gif";
import "../styles/NotFound.css"


const NotFound: React.FC = () => {
    return (
    <Grid
      container
      className="fullSize"
      direction="column"
      alignItems="center"
      id="ERR404"
    >
      <Typography level="h3">OH NO!</Typography>
      <Typography level="h4">
        Godzilla just ate this page!
      </Typography>
      <Grid container alignItems="center">
        <div id="beforeTooLate">
          Go back
          <TextLoop
            springConfig={{ stiffness: 100, damping: 12 }}
            interval={1000}
          >
            <Button variant="plain" color="neutral">
              home
            </Button>
            <Button variant="plain" color="neutral">
              home
            </Button>
          </TextLoop>
          before it's too late!
        </div>
        <img id="monsterImg" src={monster} alt="Godzilla" />
      </Grid>
    </Grid>
  );
};

export default NotFound;