import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import FormControl from '@mui/joy/FormControl';
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ColorSchemeToggle from "../components/utils/ColorSchemeToggle";
import Avatar from "@mui/joy/Avatar";
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import countries from '../models/Countries';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CountrySelector, {Country} from "../components/shared/CountrySelector";
import {useState} from "react";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
    type: HTMLInputElement;
    country: HTMLInputElement;
    username: HTMLInputElement;
    forename: HTMLInputElement;
    lastname: HTMLInputElement;
}

interface SignUpFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}


interface CountryOption {
    value: string;
    label: string;
}

export default function SignUpSide() {
    const countryOptions: readonly CountryOption[] = [
        ...countries.map((country) => ({
            value: country.code,
            label: `${country.label} +${country.phone}`,
        })),
    ];
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

    // Handler for country change
    const handleCountryChange = (event: React.SyntheticEvent, value: Country | null) => {
        setSelectedCountry(value);
    };
    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
            <CssBaseline/>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{gap: 2, display: 'flex', alignItems: 'center'}}>
                            <IconButton variant="soft" color="primary" size="sm">
                                <BadgeRoundedIcon/>
                            </IconButton>
                            <Typography level="title-lg">PanComix</Typography>
                        </Box>
                        <ColorSchemeToggle/>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{mb: 2}}>
                            <Stack gap={1}>
                                <Typography level="h3">Sign up</Typography>
                                <Typography level="body-sm">
                                    Already a member?{' '}
                                    <Link href="#replace-with-a-link" level="title-sm">
                                        Sign in!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: {xs: '#FFF', md: 'text.tertiary'},
                                    '--Divider-lineColor': {
                                        xs: '#FFF',
                                        md: 'var(--joy-palette-divider)',
                                    },
                                },
                            })}
                        >
                            or
                        </Divider>
                        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                            <Avatar
                                sx={{margin: '0 auto'}}
                                variant="outlined"
                                size="sm"
                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                            />
                        </Box>
                        <Stack gap={4} sx={{mt: 2}}>
                            <form
                                onSubmit={(event: React.FormEvent<SignUpFormElement>) => {
                                    event.preventDefault();
                                    const formElements = event.currentTarget.elements;
                                    const data = {
                                        email: formElements.email.value,
                                        password: formElements.password.value,
                                        type: formElements.password.value,
                                        country: formElements.password.value,
                                        username: formElements.password.value,
                                        forename: formElements.password.value,
                                        lastname: formElements.password.value,
                                        persistent: formElements.persistent.checked,
                                    };
                                    alert(JSON.stringify(data, null, 2));
                                }}
                            >
                                <FormControl required>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" name="email"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name="password"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Type</FormLabel>
                                    <Select defaultValue="artist" id="usertype" name="usertype">
                                        <Option value="artist">Artist</Option>
                                        <Option value="reader">Reader</Option>
                                    </Select>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Country</FormLabel>
                                    <CountrySelector
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        placeholder="Choose a country"
                                    />
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Username</FormLabel>
                                    <Input type="username" name="username"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Forename</FormLabel>
                                    <Input type="forename" name="forename"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Lastname</FormLabel>
                                    <Input type="lastname" name="lastname"/>
                                </FormControl>
                                <Stack gap={4} sx={{mt: 2}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox size="sm"
                                                  label="I have read, understood and agree with the terms and conditions"
                                                  name="persistent"/>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox size="sm" label="I agree with the privacy policy" name="persistent"/>
                                    </Box>
                                    <Button type="submit" fullWidth>
                                        Sign Up
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{py: 3}}>
                        <Typography level="body-xs" textAlign="center">
                            © PanComix {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
        </CssVarsProvider>
    );
}