import SearchIcon from '@mui/icons-material/Search';
import * as React from "react";
import Button from '@mui/joy/Button';


export default function NavigationButtonGroup() {
    return (
        <Button disabled color="danger" variant="plain" sx={{
            bgcolor: 'background.paper',
            top:0,
            bottom:0
        }}>
            <SearchIcon/>
        </Button>
    );
}