import * as React from 'react';
import Button from '@mui/joy/Button';
import Visibility from '@mui/icons-material/Visibility';

interface ViewButtonProps {
  label: 'Preview' | 'Read';
}

const ViewButton: React.FC<ViewButtonProps> = ({ label }) => {
  return (
    <Button startDecorator={<Visibility />}>
      {label}
    </Button>
  );
};

export default ViewButton;
