import React from "react";
import Footer from "../components/shared/Footer";
import NavigationBar from "../components/shared/NavigationBar";
import UploadChapter from "../components/chapter/UploadChapter";
import FilterContainer from "../components/Filters/FilterContainer";
import Stack from "@mui/material/Stack";

const UploadChapterPage: React.FC = () => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <NavigationBar/>
            <FilterContainer/>
            <UploadChapter/>
            <Footer/>
        </Stack>

    )
}

export default UploadChapterPage