// import * as React from 'react';
// import Card from '@mui/joy/Card';
// import CardContent from '@mui/joy/CardContent';
// import Typography from '@mui/joy/Typography';
// import AspectRatio from '@mui/joy/AspectRatio';
// import CardOverflow from '@mui/joy/CardOverflow';
// import Divider from '@mui/joy/Divider';
// import {Book, formatReadings} from '../../models/Book';
// import BookRating from "../utils/Rating";
// import Avatar from '@mui/joy/Avatar';
// import Box from '@mui/joy/Box';
// import CardCover from '@mui/joy/CardCover';
// import Chip from '@mui/joy/Chip';
// import IconButton from '@mui/joy/IconButton';
// import Link from '@mui/joy/Link';
// import Favorite from '@mui/icons-material/Favorite';
// import Visibility from '@mui/icons-material/Visibility';
// import CreateNewFolder from '@mui/icons-material/CreateNewFolder';
// import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
// import {useNavigate} from "react-router-dom";
//
// interface BookCardProps {
//     book: Book;
// }
//
// type GenreColors = {
//     [key: string]: string;
// };
//
// const genreColors: GenreColors = {
//     Action: '#f44336',    // Red
//     Adventure: '#2196F3', // Blue
//     Romance: '#E91E63',   // Pink
//     Fantasy: '#8E24AA',   // Purple (or any other appropriate color)
// };
//
// export default function BookCard({book}: BookCardProps) {
//
//     const getGenreColor = (genre: string) => {
//         return genreColors[genre] || '#000'; // Default to black if genre is not in the mapping
//     };
//
//     const formattedReadings = formatReadings(book.readings);
//
//     const navigate = useNavigate();
//
//     const ViewBookChapters = () => {
//         navigate('/chapter');
//     };
//
//     return (
//         <Card sx={{minHeight: '280px', width: 150}}>
//             <CardCover>
//                 <img
//                     src="https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320"
//                     srcSet="https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"
//                     loading="lazy"
//                     alt=""
//                 onClick={ViewBookChapters}/>
//             </CardCover>
//             <CardCover
//                 sx={{
//                     background:
//                         'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
//                 }}
//             />
//             <CardContent sx={{justifyContent: 'flex-end'}}>
//                 <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
//                     <Typography level="title-lg" textColor="#fff" onClick={ViewBookChapters}>
//                         {book.title}
//                     </Typography>
//                     <Divider orientation="horizontal"/>
//                     <Typography textColor="neutral.300">
//                         {book.author}
//                     </Typography>
//                 </div>
//                 <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
//                     <Typography textColor={getGenreColor(book.genre)}>
//                         {book.genre}
//                     </Typography>
//                     <div style={{display: 'flex', alignItems: 'center'}}>
//                         <Visibility/>
//                         <Typography textColor="#004d40">
//                             {formattedReadings}
//                         </Typography>
//                     </div>
//                 </div>
//             </CardContent>
//         </Card>
//     );
// }
//

import React from 'react';
import {
    Card,
    Box,
    Typography,
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
    IconButton,
    CardOverflow,
    AspectRatio,
} from '@mui/joy';
import {
    EditRounded,
    ShareRounded,
    DeleteRounded,
    MoreVertRounded,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded.js";
import EditRoundedIcon from "@mui/icons-material/EditRounded.js";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded.js";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded.js";

// Define the Book interface
export interface Book {
    book_id: number;
    book_uuid: string;
    title: string;
    year: number;
    language: string;
    author: string;
    description: string;
    coverImage: string;
    status: string;
    genre: string;
    country: string;
    rating: number;
    readings: number;
}

interface BookCardProps {
    book: Book;
}

const BookCard: React.FC<BookCardProps> = ({
    book: {
        title,
        year,
        language,
        author,
        description,
        coverImage,
        genre,
        country,
        rating,
        readings,
    },
}) => (
    <Card variant="outlined" size="sm">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
                <Typography level="title-md">{title}</Typography>
                {/*<Typography level="body-sm">Year: {year}</Typography>*/}
                {/*<Typography level="body-sm">Language: {language}</Typography>*/}
                {/*<Typography level="body-sm">Author: {author}</Typography>*/}
                <Typography level="body-sm">{genre}</Typography>
                {/*<Typography level="body-sm">Country: {country}</Typography>*/}
                {/*<Typography level="body-sm">Rating: {rating}</Typography>*/}
                {/*<Typography level="body-sm">Readings: {readings}</Typography>*/}
            </Box>
            <Dropdown>
                <MenuButton
                    variant="plain"
                    size="sm"
                    sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
                >
                    <IconButton component="span" variant="plain" color="neutral" size="sm">
                        <MoreVertRoundedIcon />
                    </IconButton>
                </MenuButton>
                <Menu
                    placement="bottom-end"
                    size="sm"
                    sx={{
                        zIndex: '99999',
                        p: 1,
                        gap: 1,
                        '--ListItem-radius': 'var(--joy-radius-sm)',
                    }}
                >
                    <MenuItem>
                        <EditRoundedIcon />
                        Edit book
                    </MenuItem>
                    <MenuItem>
                        <ShareRoundedIcon />
                        Share book
                    </MenuItem>
                    <MenuItem sx={{ textColor: 'danger.500' }}>
                        <DeleteRoundedIcon />
                        Delete book
                    </MenuItem>
                </Menu>
            </Dropdown>
        </Box>
        <CardOverflow
            sx={{
                borderBottom: '1px solid',
                borderTop: '1px solid',
                borderColor: 'neutral.outlinedBorder',
            }}
        >
            <AspectRatio ratio="16/9" color="primary" sx={{ borderRadius: 0 }}>
                <img alt={title} src={coverImage} />
            </AspectRatio>
        </CardOverflow>
        <Typography level="body-sm">{year}</Typography>
        <Typography level="body-xs">{description}</Typography>
    </Card>
);

BookCard.propTypes = {
    book: PropTypes.shape({
        book_id: PropTypes.number.isRequired,
        book_uuid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        language: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        coverImage: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        genre: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        readings: PropTypes.number.isRequired,
    }).isRequired,
};

export default BookCard;
