import * as React from 'react';
import {useEffect, useState} from "react";
import {styled} from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import axios from "axios";
import Grid from '@mui/joy/Grid';
import Box from '@mui/material/Box';
import ChapterCardHorizontal from "../../chapter/ChapterCardHorizontal";
import {Chapter} from "../../../models/Chapter"
import BookCard from "../../book/BookCard";
import {useNavigate} from "react-router-dom";



interface ChapterListGridProps {
}

const ChapterListGrid: React.FC<ChapterListGridProps> = () => {
    const [chapters, setChapters] = useState<Chapter[]>([]);


    useEffect(() => {
        // Fetch chapter data from the API
        const response = axios.get('http://localhost:7000/book/1/chapters')
            .then((response) => {
                setChapters(response.data);
            })
            .catch((error) => {
                console.error('Error fetching chapter data:', error);
            });
    }, []);




    return (
        <Grid
            container
            spacing={{xs: 1, sm: 2, md: 3}}
            columns={{xs: 6, sm: 8, md: 12}}
            sx={{flexGrow: 1, p: 10, gridTemplateColumns: 'repeat(2, 1fr)', display: 'grid'}}
            // justifyContent="flex-start"
            // alignItems="flex-start"
        >
            {chapters.map((chapter) => (
                <Grid xs='auto' sm='auto' md='auto' key={chapter.chapter_id}>
                    <ChapterCardHorizontal chapter={chapter}/>
                </Grid>
            ))}

        </Grid>
    );
}

export default ChapterListGrid