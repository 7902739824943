import * as React from 'react';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Grid from '@mui/joy/Grid';


export default function NavigationButtonGroup() {
    return (
        <Grid container>
            <ButtonGroup
            variant="plain"
            aria-label="button group"
            sx={{
                '--ButtonGroup-separatorColor': 'none !important'
            }}
        >
            <Button>Home</Button>
            <Button>Discover</Button>
            <Button>About Us</Button>
        </ButtonGroup>
        </Grid>

    );
}