import React from 'react';
import {AspectRatio, Box, Button, Divider, FormControl, Input, TabPanel, Textarea, Typography} from '@mui/joy';
import UploadButton from '../shared/UploadButton';

const buttonStyle = {
    minWidth: '100px',
    textAlign: 'right',
    alignItems: "left",
};

const ChapterForm = () => (
    <TabPanel value={0} sx={{ p: 0 }}>
        <AspectRatio ratio="21/9">
            <img alt="" src="https://images.unsplash.com/photo-1534067783941-51c9c23ecefd?w=400&h=400&auto=format" />
        </AspectRatio>
        <Box sx={{ p: 2, display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography level="title-sm" mr={1}>Upload chapter cover</Typography>
            <UploadButton />
        </Box>
        <Divider />
        <Box component="form" sx={{ gap: 2, p: 2, display: 'grid', gridTemplateColumns: '1fr', '& > *:nth-child(odd)': { color: 'text.secondary' } }}>
            <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disabled variant="plain" color="neutral" sx={buttonStyle}>Title</Button>
                    <Input placeholder="Placeholder" sx={{ flex: 1, ml: 1 }} />
                </Box>
            </FormControl>
            <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disabled variant="plain" color="neutral" sx={buttonStyle}>Country</Button>
                    <Input placeholder="Placeholder" sx={{ flex: 1, ml: 1 }} />
                </Box>
            </FormControl>
            <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disabled variant="plain" color="neutral" sx={buttonStyle}>Language</Button>
                    <Input placeholder="Placeholder" sx={{ flex: 1, ml: 1 }} />
                </Box>
            </FormControl>
            <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disabled variant="plain" color="neutral" sx={buttonStyle}>Genre</Button>
                    <Input placeholder="Placeholder" sx={{ flex: 1, ml: 1 }} />
                </Box>
            </FormControl>
            <FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button disabled variant="plain" color="neutral" sx={buttonStyle}>Description</Button>
                    <Textarea placeholder="Placeholder" sx={{ flex: 1, ml: 1 }} />
                </Box>
            </FormControl>
        </Box>
        <Divider />
        <Box sx={{ p: 2, display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography level="title-sm" mr={1}>Upload chapter</Typography>
            <UploadButton />
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="solid" color="primary">Create</Button>
        </Box>
    </TabPanel>
);

export default ChapterForm;
