export interface Chapter {
  chapter_id: number;
  chapter_uuid: string;
  chapter_number: number;
  title: string;
  year: number;
  rating: number;
  description: string;
  coverImage: string; // URL to the chapter's cover image
  readings: number;
}

export function truncateDescription(description: string, maxWords: number = 5): string {
  const words = description.split(' ');
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return description;
}
