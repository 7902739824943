// CompanyName.tsx
import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography'


const CompanyName: React.FC = () => {
  return (
    <Box>
      <Typography alignItems="center" justifyContent="center">
          PanComix
      </Typography>
    </Box>
  );
};

export default CompanyName;
