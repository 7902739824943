const reportWebVitals = (onPerfEntry: (entry: unknown) => void) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS((cls) => {
        onPerfEntry({ name: 'CLS', value: cls.value });
      });
      getFID((fid) => {
        onPerfEntry({ name: 'FID', value: fid.value });
      });
      getFCP((fcp) => {
        onPerfEntry({ name: 'FCP', value: fcp.value });
      });
      getLCP((lcp) => {
        onPerfEntry({ name: 'LCP', value: lcp.value });
      });
      getTTFB((ttfb) => {
        onPerfEntry({ name: 'TTFB', value: ttfb.value });
      });
    });
  }
};

export default reportWebVitals;
