import axios from 'axios';

const API_URL = (process.env.REACT_APP_API_URL as string);

const accessToken = localStorage.getItem('access_token');

export const signIn = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${API_URL}/auth/signin`, {email, password});
        if (response.data.status === 'success') {
            const accessToken = response.data.data.access_token;
            localStorage.setItem('access_token', accessToken);
            return {success: true, data: response.data};
        } else {
            return {success: false, message: 'Sign-in failed'};
        }
    } catch (error) {
        console.error('Error signing in:', error);
        return {success: false, message: 'Error signing in'};
    }
};

export const signUp = async (
    email: string,
    username: string,
    forename: string,
    lastname: string,
    country: string,
    phone_number: string,
    user_type: string,
    password: string
) => {
    try {
        const response = await axios.post(`${API_URL}/auth/signup`, {
            email,
            username,
            forename,
            lastname,
            country,
            phone_number,
            user_type,
            password,
        });

        if (response.data.status === 'success') {
            const accessToken = response.data.data.access_token;
            localStorage.setItem('access_token', accessToken);
            return {success: true, data: response.data};
        } else {
            return {success: false, message: 'Sign-up failed'};
        }
    } catch (error) {
        console.error('Error signing up:', error);
        return {success: false, message: 'Error signing up'};
    }
};

export const fetchCurrentBooks = async (page: number, per_page: number) => {
    try {
        const response = await axios.get(`${API_URL}/book/current?page=${page}&per_page=${per_page}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching books:', error);
        return null;
    }
};

export const fetchLatestBooks = async (page: number) => {
    try {
        const response = await axios.get(`${API_URL}/book/latest?page=${page}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.data;
    } catch (error) {
        console.error('Error fetching latest books:', error);
        return null;
    }
};

export const fetchTrendingBooks = async (page: number) => {
    try {
        const response = await axios.get(`${API_URL}/book/trending?page=${page}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        console.log('Response from API:', response);
        console.log('Data:', response.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching latest books:', error);
        return null;
    }
};

export const fetchBooks = async (page: number) => {
    try {
        const response = await axios.get(`/api/books`, {
            params: {page},
        });
        const {data: books, pagination} = response.data;

        return {
            results: books,
            total: pagination.total,
            page: pagination.page,
            per_page: pagination.per_page,
            total_pages: pagination.pages,
        };
    } catch (error) {
        console.error('Failed to fetch books', error);
        return {
            results: [],
            total: 0,
            page,
            per_page: 0,
            total_pages: 0,
        };
    }
};

export const joinWaitingList = async (formData: {
    firstname: string;
    surname: string;
    email: string;
    usertype: string;
    interestedInCommunity: string;
    publishingFrequency: string;
    publishingFrequencyOther: string;
    interestedAsEnthusiast: string;
    subscriptionAmount: string;
    subscriptionAmountOther: string;
    spendingFrequency: string;
    artistExperienceLevel: string;
    incomeGoal: string;
    comicType: string;
    comicTypeOther: string;
    tipsSubscription: string;
    artistFeatures: string;
    readerGenres: string;
    readerFeatures: string;
    additionalSuggestions: string;
}) => {
    try {
        const response = await axios.post(`${API_URL}/waiting-list/join`, formData);
        return response.data;
    } catch (error) {
        console.error('Error joining waitlist:', error);
        return { status: 'error', message: 'Failed to join waiting list' };
    }
};