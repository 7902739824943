import React from "react";
import CssBaseline from "@mui/joy/CssBaseline";
import {CssVarsProvider} from "@mui/joy/styles";
import Layout from "../components/home/Layout";
import SearchAndFilterBar from "../components/search/SearchAndFilterBar";
import Box from "@mui/joy/Box";
import Header from "../components/shared/Header";
import BookCaddy from "../components/containers/book/BookCaddy";
import {fetchBooks} from "../api_calls/api";

const SearchPage: React.FC = () => {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
                        md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
                    }
                }}
            >
                <Layout.Header>
                    <Header showSearch={false}/>
                </Layout.Header>
                <Layout.Main>
                    <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                        <Box
                            component="main"
                            className="MainContent"
                            sx={{
                                px: {xs: 2, md: 6},
                                pt: {
                                    xs: 'calc(12px + var(--Header-height))',
                                    sm: 'calc(12px + var(--Header-height))',
                                    md: 3,
                                },
                                pb: {xs: 2, sm: 2, md: 3},
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                            }}
                        >
                            <SearchAndFilterBar/>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <BookCaddy fetchBooks={fetchBooks}/>
                        </Box>
                    </Box>
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    )
}

export default SearchPage;