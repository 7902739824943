import * as React from 'react';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';
import {ChangeEvent} from 'react';

interface ChildCheckboxProps {
    label: string;
    options: string[];
    checked: boolean[];
    onChange: (index: number) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const ChildCheckbox: React.FC<ChildCheckboxProps> = ({label, options, checked, onChange}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ml: 3,
                gap: 1,
                mt: 1,
            }}
        >
            {options.map((option, index) => (
                <Checkbox
                    key={option}
                    checked={checked[index]}
                    onChange={onChange(index)}
                    label={option}
                />
            ))}
        </Box>
    );
};

export default ChildCheckbox;
