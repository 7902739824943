import React, {useEffect, useState} from "react";
import {Book} from "../../../models/Book";
import axios from "axios";
import BookCard from "../../book/BookCard";
import ContainerPageNavigation from "../../shared/ContainerPageNavigation";
import Box from '@mui/joy/Box';
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";
import {fetchLatestBooks, fetchTrendingBooks} from "../../../api_calls/api";

interface TrendingProps {
}

const Trending: React.FC<TrendingProps> = () => {
    const [books, setBooks] = useState<Book[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 5;

    useEffect(() => {
        // Function to fetch books based on the current page
        const fetchBooks = async (page: number) => {
            const data = await fetchTrendingBooks(page);
            if (data) {
                setBooks(data);
            }
        };

        fetchBooks(currentPage);
    }, [currentPage]);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Stack
            alignItems="center"
        >
            <ContainerPageNavigation
                page={currentPage}
                totalPages={Math.ceil(books.length / itemsPerPage)}
                perPage={itemsPerPage}
                onPageChange={setCurrentPage}
                text="Trending"
            />
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                columnSpacing={{xs: 1, sm: 2, md: 4}}
                sx={{width: '80%', mt: 0, mb: 0 }}
            >
                {books.map((book) => (
                    <Grid container key={book.book_id}>
                        <BookCard book={book}/>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};


export default Trending