import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, {tabClasses} from '@mui/joy/Tab';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/joy/Divider';
import AspectRatio from '@mui/joy/AspectRatio';
import IconButton from '@mui/joy/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CountrySelector, {Country} from '../components/shared/CountrySelector'; // Assuming you have this component
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import Layout from '../components/home/Layout';
import Header from "../components/shared/Header";
import CssBaseline from "@mui/joy/CssBaseline";
import {CssVarsProvider} from "@mui/joy/styles";
import Select from "@mui/joy/Select";
import Option from '@mui/joy/Option';
import Button from "@mui/joy/Button";
import {useState} from "react";

export default function MyProfile() {
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

    // Handler for country change
    const handleCountryChange = (event: React.SyntheticEvent, value: Country | null) => {
        setSelectedCountry(value);
    };
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Layout.Root>
                <Layout.Header>
                    <Header/>
                </Layout.Header>
                <Layout.Main>
                    <Box sx={{px: {xs: 2, md: 6}, py: {xs: 2, md: 3}}}>
                        <Typography level="h2" component="h1" sx={{mt: 1, mb: 2}}>
                            My profile
                        </Typography>
                        <Stack spacing={4} sx={{maxWidth: '800px', mx: 'auto', mt: 4}}>
                            <Card>
                                <Box sx={{mb: 1}}>
                                    <Typography level="title-md">Personal info</Typography>
                                    <Typography level="body-sm">
                                        Profile Settings.
                                    </Typography>
                                </Box>
                                <Divider/>
                                <Stack direction={{xs: 'column', md: 'row'}} spacing={3} sx={{my: 1}}>
                                    <Stack direction="column" spacing={1}>
                                        <AspectRatio
                                            ratio="1"
                                            maxHeight={200}
                                            sx={{flex: 1, minWidth: 120, borderRadius: '100%'}}
                                        >
                                            <img
                                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                                                srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                                                loading="lazy"
                                                alt=""
                                            />
                                        </AspectRatio>
                                        <IconButton
                                            aria-label="upload new picture"
                                            size="sm"
                                            variant="outlined"
                                            color="neutral"
                                            sx={{
                                                bgcolor: 'background.body',
                                                position: 'absolute',
                                                zIndex: 2,
                                                borderRadius: '50%',
                                                left: 100,
                                                top: 170,
                                                boxShadow: 'sm',
                                            }}
                                        >
                                            <EditRoundedIcon/>
                                        </IconButton>
                                    </Stack>
                                    <Stack direction="column" spacing={1}>
                                        <AspectRatio ratio="1" maxHeight={200} sx={{borderRadius: '100%'}}>
                                            <img
                                                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                                                alt="Profile"
                                            />
                                        </AspectRatio>
                                        <IconButton
                                            aria-label="upload new picture"
                                            size="sm"
                                            variant="outlined"
                                            color="neutral"
                                            sx={{
                                                bgcolor: 'background.body',
                                                position: 'absolute',
                                                zIndex: 2,
                                                borderRadius: '50%',
                                                left: 100,
                                                top: 170
                                            }}
                                        >
                                            <EditRoundedIcon/>
                                        </IconButton>
                                    </Stack>
                                    <Stack spacing={2} sx={{flexGrow: 1}}>
                                        <FormControl sx={{gap: 2}}>
                                            <FormLabel>Name</FormLabel>
                                            <Stack direction={{sm: 'column', md: 'row'}} spacing={2}>
                                                <Input size="sm" placeholder="First name"/>
                                                <Input size="sm" placeholder="Last name" sx={{flexGrow: 1}}/>
                                            </Stack>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Role</FormLabel>
                                            <Input size="sm" defaultValue="UI Developer"/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Email</FormLabel>
                                            <Input size="sm" type="email" startDecorator={<EmailRoundedIcon/>}
                                                   defaultValue="siriwatk@test.com"/>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Country</FormLabel>
                                             <Box sx={{flex: 0, ml: 0}}>
                                                <CountrySelector
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    placeholder="Choose a country"
                                                />
                                            </Box>
                                        </FormControl>
                                        <FormControl sx={{display: {sm: 'contents'}}}>
                                            <FormLabel>Timezone</FormLabel>
                                            <Select
                                                size="sm"
                                                startDecorator={<AccessTimeFilledRoundedIcon/>}
                                                defaultValue="1"
                                            >
                                                <Option value="1">
                                                    Indochina Time (Bangkok){' '}
                                                    <Typography textColor="text.tertiary" ml={0.5}>
                                                        — GMT+07:00
                                                    </Typography>
                                                </Option>
                                                <Option value="2">
                                                    Indochina Time (Ho Chi Minh City){' '}
                                                    <Typography textColor="text.tertiary" ml={0.5}>
                                                        — GMT+07:00
                                                    </Typography>
                                                </Option>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                                    <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                                        <Button size="sm" variant="outlined" color="neutral">
                                            Cancel
                                        </Button>
                                        <Button size="sm" variant="solid">
                                            Save
                                        </Button>
                                    </CardActions>
                                </CardOverflow>
                            </Card>
                        </Stack>
                    </Box>
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
