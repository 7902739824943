import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, CircularProgress, Box } from '@mui/joy';
import BookCard, { Book } from '../../book/BookCard';

interface BookCaddyProps {
    fetchBooks: (page: number) => Promise<{
        results: Book[];
        total: number;
        page: number;
        per_page: number;
        total_pages: number;
    }>;
}

const BookCaddy: React.FC<BookCaddyProps> = ({ fetchBooks }) => {
    const [books, setBooks] = useState<Book[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const observer = useRef<IntersectionObserver | null>(null);
    const lastBookElementRef = useCallback(
    (node: HTMLDivElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries.length === 0 || !entries[0]) return; // Ensure entries[0] is defined
            const entry = entries[0];
            if (entry.isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    },
    [loading, hasMore]
);

    useEffect(() => {
        const loadBooks = async () => {
            setLoading(true);
            const { results: newBooks, total_pages: totalPages } = await fetchBooks(page);
            setBooks((prevBooks) => [...prevBooks, ...newBooks]);
            setHasMore(page < totalPages);
            setLoading(false);
        };

        loadBooks();
    }, [page, fetchBooks]);

    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                {books.map((book, index) => {
                    const isLastBook = books.length === index + 1;
                    return (
                        <Grid
                            // item
                            xs={12} sm={6} md={4} lg={2}
                            key={book.book_id}
                            ref={isLastBook ? lastBookElementRef : null}
                        >
                            <BookCard book={book} />
                        </Grid>
                    );
                })}
            </Grid>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default BookCaddy;