import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import Layout from '../components/home/Layout';
import Header from "../components/shared/Header";
import DailyChoice from "../components/containers/DailyChoice";
import CurrentReading from "../components/containers/book/CurrentReading";
import Latest from "../components/containers/book/Latest";
import Trending from "../components/containers/book/Trending";

const buttonStyle = {
    minWidth: '100px', // Set the desired width here
    textAlign: 'right',
    alignItems: "left",
};

const HomePage: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Stack
                id="tab-bar"
                direction="row"
                justifyContent="space-around"
                spacing={1}
                sx={{
                    display: {xs: 'flex', sm: 'none'},
                    zIndex: '999',
                    bottom: 0,
                    position: 'fixed',
                    width: '100dvw',
                    py: 2,
                    backgroundColor: 'background.body',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/email/"
                    size="sm"
                    startDecorator={<EmailRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    Email
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/team/"
                    size="sm"
                    startDecorator={<PeopleAltRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    Team
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    aria-pressed="true"
                    component="a"
                    href="/joy-ui/getting-started/templates/files/"
                    size="sm"
                    startDecorator={<FolderRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    File
                </Button>
            </Stack>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
                        md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
                    }
                }}
            >
                <Layout.Header>
                    <Header/>
                </Layout.Header>
                <Layout.Main>
                    <Box
                        sx={{
                             display: 'grid',
                            // gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                            width: '100%',
                        }}
                    >
                        <Box>
                            <DailyChoice/>
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                // gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                                gap: 2,
                            }}>
                            <CurrentReading/>

                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                                gap: 2,
                            }}>
                            <Latest/>
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                                gap: 2,
                            }}>
                            <Trending/>
                        </Box>
                    </Box>
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
export default HomePage;


