import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Divider from '@mui/joy/Divider';
import Sheet from '@mui/joy/Sheet';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListDivider from '@mui/joy/ListDivider';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Stack from '@mui/joy/Stack';
import Chip from '@mui/joy/Chip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Layout from '../components/shared/Layout';
import Header from "../components/shared/Header";
import {LocationOn, Navigation} from "@mui/icons-material";
import UploadButton from "../components/shared/UploadButton";
import {Textarea} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import FormHelperText from "@mui/joy/FormHelperText";
import BookCard from "../components/book/BookCard";
import PropTypes from "prop-types";
import BookForm from "../components/book/BookForm";
import ChapterCard from "../components/chapter/ChapterCard";
import ChapterForm from "../components/chapter/ChapterForm";

const buttonStyle = {
    minWidth: '100px', // Set the desired width here
    textAlign: 'right',
    alignItems: "left",
};

const BookCreationPage: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            {drawerOpen && (
                <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                    <Navigation/>
                </Layout.SideDrawer>
            )}
            <Stack
                id="tab-bar"
                direction="row"
                justifyContent="space-around"
                spacing={1}
                sx={{
                    display: {xs: 'flex', sm: 'none'},
                    zIndex: '999',
                    bottom: 0,
                    position: 'fixed',
                    width: '100dvw',
                    py: 2,
                    backgroundColor: 'background.body',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/email/"
                    size="sm"
                    startDecorator={<EmailRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    Email
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/joy-ui/getting-started/templates/team/"
                    size="sm"
                    startDecorator={<PeopleAltRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    Team
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    aria-pressed="true"
                    component="a"
                    href="/joy-ui/getting-started/templates/files/"
                    size="sm"
                    startDecorator={<FolderRoundedIcon/>}
                    sx={{flexDirection: 'column', '--Button-gap': 0}}
                >
                    Files
                </Button>
            </Stack>
            <Layout.Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
                        md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <Header/>
                </Layout.Header>
                <Layout.SideNav>
                    <Navigation/>
                </Layout.SideNav>
                <Layout.Main>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                            gap: 2,
                        }}
                    >
                        <ChapterCard
                            chapter={{
                                chapter_id: 1,
                                chapter_uuid: "afwfqrfq",
                                chapter_number: 1,
                                title: "Chapter Card",
                                year: 2024, // should be a number
                                description: "Test",
                                coverImage: "https://images.unsplash.com/photo-1621351183012-e2f9972dd9bf?w=400&h=400&auto=format",
                                rating: 5, // should be a number
                                readings: 1 // should be a number
                            }}
                        />
                    </Box>
                </Layout.Main>
                <Sheet
                    sx={{
                        display: {xs: 'none', sm: 'initial'},
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Box sx={{p: 2, display: 'flex', alignItems: 'center'}}>
                        <Typography level="title-md" sx={{flex: 1}}>
                            Add a new chapter
                        </Typography>
                        <IconButton component="span" variant="plain" color="neutral" size="sm">
                            <CloseRoundedIcon/>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <Tabs>
                        <ChapterForm/>
                    </Tabs>
                </Sheet>
            </Layout.Root>
        </CssVarsProvider>
    );
}
export default BookCreationPage;

