import React, {useState} from 'react';
import Avatar from '@mui/joy/Avatar';
import ProfileDrawerContainer from "../containers/Profile/ProfileDrawerContainer";


interface ProfileImageProps {
    profile_image?: string; // Add a type annotation for the 'text' prop
}

const ProfileImage: React.FC<ProfileImageProps> = ({profile_image}) => {
    const defaultAvatar = '../assets/avatar.svg'; // Provide the path to your default avatar image
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


    return (
        <>
            <Avatar alt="Profile" src={defaultAvatar} onClick={toggleDrawer}/>
            <ProfileDrawerContainer isOpen={isDrawerOpen} onClose={toggleDrawer}/>
        </>
    );
};

export default ProfileImage;

