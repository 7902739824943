import React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import {Chapter, truncateDescription} from "../../models/Chapter";
import Divider from "@mui/joy/Divider";
import {formatReadings} from "../../models/Book";
import Rating from "@mui/material/Rating";
import {useNavigate} from "react-router-dom";

interface ChapterCardHorizontalProps {
    chapter: Chapter;
}

const ChapterCardHorizontal: React.FC<ChapterCardHorizontalProps> = ({chapter}) => {
    const truncatedDescription = truncateDescription(chapter.description, 10);
    const formattedReadings = formatReadings(chapter.readings);
    const navigate = useNavigate();

    const readChapter = () => {
        navigate('/render');
    };
    return (
        <Card orientation="horizontal" variant="outlined" sx={{width: 'auto'}}>
            <CardOverflow>
                <AspectRatio ratio="1" sx={{maxWidth: 90, width: 90, height: 90}}>
                    <img
                        src={chapter.coverImage}
                        alt={chapter.title}
                    onClick={readChapter}/>
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Typography fontWeight="md" textColor="success.plainColor" onClick={readChapter}>
                    Chapter: {chapter.chapter_number}
                </Typography>
                <Typography fontWeight="md" textColor="success.plainColor">
                    {chapter.title}
                </Typography>
            </CardContent>
            <CardOverflow variant="soft">
                <Divider inset="context"/>
                <CardContent orientation="horizontal">
                    <Typography level="body-xs">6.3k views</Typography>
                    <Divider orientation="vertical"/>
                    <Typography level="body-xs">1 hour ago</Typography>
                </CardContent>
                <Divider orientation="vertical"/>
                <CardContent orientation="horizontal">
                    <Typography level="body-xs"><Rating name="size-small" defaultValue={2} size="small"/></Typography>
                    <Divider orientation="vertical"/>
                    <Typography level="body-xs">1 hour ago</Typography>
                </CardContent>
            </CardOverflow>
        </Card>
    );
};

export default ChapterCardHorizontal;
