import * as React from 'react';
import Box from '@mui/joy/Box';
import UploadButton from '../shared/UploadButton';
import ViewButton from '../shared/ViewButton';


export default function UploadChapter() {
  return (
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      <UploadButton/>
      <ViewButton label={"Preview"}/>
    </Box>
  );
}
