import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from "./Router";
import reportWebVitals from './reportWebVitals';
import './i18n'

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  );
}

reportWebVitals(console.log);
