import * as React from 'react';
import Grid from "@mui/joy/Grid";
import ChildCheckbox from './ChildCheckbox';
import ParentCheckbox from './ParentCheckbox';

const FilterContainer: React.FC = () => {
    const [statusChecked, setStatusChecked] = React.useState<boolean[]>(new Array(3).fill(false));
    const [languageChecked, setLanguageChecked] = React.useState<boolean[]>(new Array(3).fill(false));
    const [genreChecked, setGenreChecked] = React.useState<boolean[]>(new Array(5).fill(false));

    const [openStatus, setOpenStatus] = React.useState(false);
    const [openLanguage, setOpenLanguage] = React.useState(false);
    const [openGenre, setOpenGenre] = React.useState(false);

    const handleChildChange = (setState: React.Dispatch<React.SetStateAction<boolean[]>>) => (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target) {
            return;
        }

        const checkedValue = event.target.checked;
        setState((prevChecked) => {
            const newChecked = [...prevChecked];
            newChecked[index] = checkedValue;
            return newChecked;
        });
    };

    return (
        <Grid container justifyContent="center" spacing={2}>
            <ParentCheckbox
                label="Status"
                checked={statusChecked.every(Boolean)}
                indeterminate={!statusChecked.every(Boolean) && statusChecked.some(Boolean)}
                onClick={() => setOpenStatus(!openStatus)}
            />
            {openStatus && (
                <ChildCheckbox label="Status" options={['Ongoing', 'Cancelled', 'Completed']} checked={statusChecked}
                               onChange={handleChildChange(setStatusChecked)}/>
            )}

            <ParentCheckbox
                label="Language"
                checked={languageChecked.every(Boolean)}
                indeterminate={!languageChecked.every(Boolean) && languageChecked.some(Boolean)}
                onClick={() => setOpenLanguage(!openLanguage)}
            />
            {openLanguage && (
                <ChildCheckbox label="Language" options={['English', 'French', 'Spanish']} checked={languageChecked}
                               onChange={handleChildChange(setLanguageChecked)}/>
            )}

            <ParentCheckbox
                label="Genre"
                checked={genreChecked.every(Boolean)}
                indeterminate={!genreChecked.every(Boolean) && genreChecked.some(Boolean)}
                onClick={() => setOpenGenre(!openGenre)}
            />
            {openGenre && (
                <ChildCheckbox label="Genre" options={['Action', 'Adventure', 'Comedy', 'Historical', 'Drama']}
                               checked={genreChecked} onChange={handleChildChange(setGenreChecked)}/>
            )}
        </Grid>
    );
};

export default FilterContainer;
