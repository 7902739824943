import { Box } from "@mui/system";
import * as React from "react";
import ChapterDocumentRender from "../../chapter/ChapterDocumentRender";


interface ChapterViewProps {
}



const ChapterView: React.FC<ChapterViewProps> = () => {
    return (
        <Box>
            <ChapterDocumentRender/>
        </Box>
    );
}

export default ChapterView