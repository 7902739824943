import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {Document, Page, pdfjs} from 'react-pdf';
import Grid from '@mui/joy/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/joy/Card';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/joy/Typography';
import MenuIcon from '@mui/icons-material/Menu';

import axios from "axios";
import {useResizeObserver} from "@wojtekmaj/react-hooks";
// import "../../styles/ChapterDocumentRender.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};
const resizeObserverOptions = {};

const maxWidth = 400;
const pageWidth = 400; // Adjust to your preferred page width
const pageHeight = 600; // Adjust to your preferred page height
const pagesPerSpread = 2;
type PDFFile = string | File | null;

interface ChapterDocumentRenderProps {
}

const ChapterDocumentRender: React.FC<ChapterDocumentRenderProps> = () => {
    // const url = "https://drive.google.com/uc?export=download&id=1KodbdYdMxOtDLUtcz_d0Vvkx2PvrHfDh";
    const url = "https://github.com/ghostgeek6113/UzishaItemPredictor/blob/d76ae42a3d624e81fb77ed87eb02cfe74ee18a5b/compressed.tracemonkey-pldi-09.pdf"
    const [pdfData, setPdfData] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [file, setFile] = useState<PDFFile>("./Z.pdf");
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();
    const theme = useTheme();
    const navigate = useNavigate();


    useEffect(() => {
        // Disable right-click on the document
        const preventRightClick = (e: MouseEvent) => {
            e.preventDefault();
        };

        document.addEventListener("contextmenu", preventRightClick);

        // Detect screenshot attempts
        const detectScreenshots = () => {
            const screenshotWarning = "Are you sure you want to take a screenshot?";
            window.addEventListener("keyup", (e) => {
                if (e.key === "PrintScreen" || (e.key === "c" && e.ctrlKey)) {
                    if (window.confirm(screenshotWarning)) {
                        // Redirect to the home page
                        window.location.href = "/";
                    }
                }
            });

            window.addEventListener("keydown", (e) => {
                if (e.key === "PrintScreen" || (e.key === "c" && e.ctrlKey)) {
                    if (window.confirm(screenshotWarning)) {
                        // Redirect to the home page
                        window.location.href = "/";
                    }
                }
            });
        };

        detectScreenshots();

        return () => {
            document.removeEventListener("contextmenu", preventRightClick);
        };
    }, []);


    function onDocumentLoadSuccess({numPages}: {
        numPages: number
    }) {
        console.log("Document loaded successfully:", numPages);
        setNumPages(numPages);
    }

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextSpread = () => {
        if (pageNumber + pagesPerSpread <= numPages) {
            setPageNumber(pageNumber + pagesPerSpread);
        }
    };

    const handlePrevSpread = () => {
        if (pageNumber - pagesPerSpread >= 1) {
            setPageNumber(pageNumber - pagesPerSpread);
        }
    };

    const openChapterViewPage = () => {
        navigate('/chapter');
    };

    return (
        <Box>
            <Card variant="outlined" sx={{justifyContent: "center", alignItems: "center"}}>
                <MenuIcon onClick={openChapterViewPage} />
                <Typography level="title-sm">
                    National Parks
                </Typography>
            </Card>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {Array.from(new Array(pagesPerSpread), (el, index) => (
                        <Page
                            key={`page_${pageNumber + index}`}
                            pageNumber={pageNumber + index}
                            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                            height={pageHeight}
                            renderAnnotationLayer={false}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10, // Adjust the bottom position
                                    right: 10, // Adjust the right position
                                    color: "red", // Annotation text color
                                    fontSize: "16px", // Annotation text size
                                    fontWeight: "bold",
                                }}
                            >
                                PanComix
                            </div>
                        </Page>
                    ))}
                </Grid>
            </Document>
            <MobileStepper
                variant="text"
                steps={numPages}
                position="static"
                activeStep={pageNumber}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNextSpread}
                        disabled={pageNumber === numPages - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft/>
                        ) : (
                            <KeyboardArrowRight/>
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handlePrevSpread} disabled={pageNumber === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight/>
                        ) : (
                            <KeyboardArrowLeft/>
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default ChapterDocumentRender;
