import React from "react";
import ChapterListGrid from "../components/containers/chapter/ChapterListGrid";
import {Grid} from "@mui/material";
import DailyChoice from "../components/containers/DailyChoice";
import Footer from "../components/shared/Footer";
import NavigationBar from "../components/shared/NavigationBar";

const centerContainerStyle = {
    margin: '0 auto', // Center the container horizontally
    maxWidth: '100%', // Set a maximum width to avoid excessive width on larger screens
    overflowX: 'hidden', // Hide horizontal overflow
};

const ChapterViewPage: React.FC = () => {
    return (
        <Grid>
            <Grid xs={12} sx={centerContainerStyle}>
                <NavigationBar/>
            </Grid>
            <Grid xs={12} sx={centerContainerStyle}>
                <DailyChoice/>
            </Grid>
            <Grid xs={12} sx={centerContainerStyle}>
                <ChapterListGrid/>
            </Grid>
            <Grid xs={12} sx={centerContainerStyle}>
                <Footer/>
            </Grid>
        </Grid>
    );
};

export default ChapterViewPage;
