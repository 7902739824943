import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Checkbox from '@mui/joy/Checkbox';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Sheet from '@mui/joy/Sheet';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import TuneIcon from '@mui/icons-material/TuneRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import Done from '@mui/icons-material/Done';
import SideBarDrawer from '../../profile/SideBarDrawer';
import UserMiniProfileCard from "../../profile/UserMiniProfileCard";
import { ThemeProvider } from '@mui/system';
import Grid from '@mui/joy/Grid';
import MenuButton from "@mui/joy/MenuButton";
import Avatar from "@mui/joy/Avatar";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Dropdown from "@mui/joy/Dropdown";

interface ProfileSideBarDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

// const ProfileDrawerContainer: React.FC<ProfileSideBarDrawerProps> = ({isOpen, onClose}) => {
//     return (
//         <Grid container
//               sx={{
//                 position: 'fixed',
//                 top: 0,
//                 left: isOpen ? 0 : '-100%', // Adjust the width as needed
//                 // width: '200px', // Adjust the width as needed
//                 height: '100%', // Adjust the height as needed
//                 backgroundColor: '#fff',
//                 boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
//                 zIndex: 3, // Ensure it's above other elements
//                 transition: 'left 0.3s ease-in-out',
//                   // justifyContent: "center"
//             }}
//         >
//             <SideBarDrawer/>
//         </Grid>
//     );
// };
//
// export default ProfileDrawerContainer;


const ProfileDrawerContainer: React.FC<ProfileSideBarDrawerProps> = ({isOpen, onClose}) => {
    // const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState('Guesthouse');
    const [amenities, setAmenities] = React.useState([0, 6]);

    return (
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar
              src="https://i.pravatar.cc/40?img=2"
              srcSet="https://i.pravatar.cc/80?img=2"
              sx={{ maxWidth: '32px', maxHeight: '32px' }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src="https://i.pravatar.cc/40?img=2"
                  srcSet="https://i.pravatar.cc/80?img=2"
                  sx={{ borderRadius: '50%' }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    Rick Sanchez
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    rick@email.com
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            <MenuItem>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            <ListDivider />
            <MenuItem component="a" href="/blog/first-look-at-joy/">
              First look at Joy UI
              <OpenInNewRoundedIcon />
            </MenuItem>
            <MenuItem
              component="a"
              href="https://github.com/mui/material-ui/tree/master/docs/data/joy/getting-started/templates/email"
            >
              Sourcecode
              <OpenInNewRoundedIcon />
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
        // <React.Fragment>
        //     <Drawer
        //         anchor="right"
        //         size="md"
        //         variant="plain"
        //         open={isOpen}
        //         onClose={onClose}
        //         slotProps={{
        //             content: {
        //                 sx: {
        //                     bgcolor: 'transparent',
        //                     p: {md: 3, sm: 0},
        //                     boxShadow: 'none',
        //                 },
        //             },
        //         }}
        //     >
        //         <Sheet
        //             sx={{
        //                 borderRadius: 'md',
        //                 p: 2,
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 gap: 2,
        //                 height: '100%',
        //                 overflow: 'auto',
        //             }}
        //         >
        //             <ModalClose/>
        //             <Divider sx={{mt: 'auto'}}/>
        //             <DialogContent sx={{gap: 0}}>
        //                 <Grid container justifyContent="center" alignItems="center" xs={8}>
        //                         <UserMiniProfileCard/>
        //                 </Grid>
        //                 <SideBarDrawer/>
        //                 {/*<FormControl>*/}
        //                 {/*    <FormLabel sx={{typography: 'title-md', fontWeight: 'bold'}}>*/}
        //                 {/*        Property type*/}
        //                 {/*    </FormLabel>*/}
        //                 {/*    <RadioGroup*/}
        //                 {/*        value={type || ''}*/}
        //                 {/*        onChange={(event) => {*/}
        //                 {/*            setType(event.target.value);*/}
        //                 {/*        }}*/}
        //                 {/*    >*/}
        //                 {/*        <Box*/}
        //                 {/*            sx={{*/}
        //                 {/*                display: 'grid',*/}
        //                 {/*                gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',*/}
        //                 {/*                gap: 1.5,*/}
        //                 {/*            }}*/}
        //                 {/*        >*/}
        //                 {/*            {[*/}
        //                 {/*                {*/}
        //                 {/*                    name: 'House',*/}
        //                 {/*                    icon: <HomeRoundedIcon/>,*/}
        //                 {/*                },*/}
        //                 {/*                {*/}
        //                 {/*                    name: 'Apartment',*/}
        //                 {/*                    icon: <ApartmentRoundedIcon/>,*/}
        //                 {/*                },*/}
        //                 {/*                {*/}
        //                 {/*                    name: 'Guesthouse',*/}
        //                 {/*                    icon: <MeetingRoomRoundedIcon/>,*/}
        //                 {/*                },*/}
        //                 {/*                {*/}
        //                 {/*                    name: 'Hotel',*/}
        //                 {/*                    icon: <HotelRoundedIcon/>,*/}
        //                 {/*                },*/}
        //                 {/*            ].map((item) => (*/}
        //                 {/*                <Card*/}
        //                 {/*                    key={item.name}*/}
        //                 {/*                    sx={{*/}
        //                 {/*                        boxShadow: 'none',*/}
        //                 {/*                        '&:hover': {bgcolor: 'background.level1'},*/}
        //                 {/*                    }}*/}
        //                 {/*                >*/}
        //                 {/*                    <CardContent>*/}
        //                 {/*                        {item.icon}*/}
        //                 {/*                        <Typography level="title-md">{item.name}</Typography>*/}
        //                 {/*                    </CardContent>*/}
        //                 {/*                    <Radio*/}
        //                 {/*                        disableIcon*/}
        //                 {/*                        overlay*/}
        //                 {/*                        checked={type === item.name}*/}
        //                 {/*                        variant="outlined"*/}
        //                 {/*                        color="neutral"*/}
        //                 {/*                        value={item.name}*/}
        //                 {/*                        sx={{mt: -2}}*/}
        //                 {/*                        slotProps={{*/}
        //                 {/*                            action: {*/}
        //                 {/*                                sx: {*/}
        //                 {/*                                    ...(type === item.name && {*/}
        //                 {/*                                        borderWidth: 2,*/}
        //                 {/*                                        borderColor:*/}
        //                 {/*                                            'var(--joy-palette-primary-outlinedBorder)',*/}
        //                 {/*                                    }),*/}
        //                 {/*                                    '&:hover': {*/}
        //                 {/*                                        bgcolor: 'transparent',*/}
        //                 {/*                                    },*/}
        //                 {/*                                },*/}
        //                 {/*                            },*/}
        //                 {/*                        }}*/}
        //                 {/*                    />*/}
        //                 {/*                </Card>*/}
        //                 {/*            ))}*/}
        //                 {/*        </Box>*/}
        //                 {/*    </RadioGroup>*/}
        //                 {/*</FormControl>*/}
        //
        //             {/*    <Typography level="title-md" fontWeight="bold" sx={{mt: 1}}>*/}
        //             {/*        Amenities*/}
        //             {/*    </Typography>*/}
        //             {/*    <div role="group" aria-labelledby="rank">*/}
        //             {/*        <List*/}
        //             {/*            orientation="horizontal"*/}
        //             {/*            size="sm"*/}
        //             {/*            sx={{*/}
        //             {/*                '--List-gap': '12px',*/}
        //             {/*                '--ListItem-radius': '20px',*/}
        //             {/*            }}*/}
        //             {/*        >*/}
        //             {/*            {['Wi-fi', 'Washer', 'A/C', 'Kitchen'].map((item, index) => {*/}
        //             {/*                const selected = amenities.includes(index);*/}
        //             {/*                return (*/}
        //             {/*                    <ListItem key={item}>*/}
        //             {/*                        <AspectRatio*/}
        //             {/*                            variant={selected ? 'solid' : 'outlined'}*/}
        //             {/*                            color={selected ? 'primary' : 'neutral'}*/}
        //             {/*                            ratio={1}*/}
        //             {/*                            sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}*/}
        //             {/*                        >*/}
        //             {/*                            <div>{selected && <Done/>}</div>*/}
        //             {/*                        </AspectRatio>*/}
        //             {/*                        <Checkbox*/}
        //             {/*                            size="sm"*/}
        //             {/*                            color="neutral"*/}
        //             {/*                            disableIcon*/}
        //             {/*                            overlay*/}
        //             {/*                            label={item}*/}
        //             {/*                            variant="outlined"*/}
        //             {/*                            checked={selected}*/}
        //             {/*                            onChange={(event) =>*/}
        //             {/*                                setAmenities((prev) => {*/}
        //             {/*                                    const set = new Set([...prev, index]);*/}
        //             {/*                                    if (!event.target.checked) {*/}
        //             {/*                                        set.delete(index);*/}
        //             {/*                                    }*/}
        //             {/*                                    // @ts-ignore*/}
        //             {/*                                    return [...set];*/}
        //             {/*                                })*/}
        //             {/*                            }*/}
        //             {/*                            slotProps={{*/}
        //             {/*                                action: {*/}
        //             {/*                                    sx: {*/}
        //             {/*                                        '&:hover': {*/}
        //             {/*                                            bgcolor: 'transparent',*/}
        //             {/*                                        },*/}
        //             {/*                                    },*/}
        //             {/*                                },*/}
        //             {/*                            }}*/}
        //             {/*                        />*/}
        //             {/*                    </ListItem>*/}
        //             {/*                );*/}
        //             {/*            })}*/}
        //             {/*        </List>*/}
        //             {/*    </div>*/}
        //
        //             {/*    <Typography level="title-md" fontWeight="bold" sx={{mt: 2}}>*/}
        //             {/*        Booking options*/}
        //             {/*    </Typography>*/}
        //             {/*    <FormControl orientation="horizontal">*/}
        //             {/*        <Box sx={{flex: 1, pr: 1}}>*/}
        //             {/*            <FormLabel sx={{typography: 'title-sm'}}>*/}
        //             {/*                Instant booking*/}
        //             {/*            </FormLabel>*/}
        //             {/*            <FormHelperText sx={{typography: 'body-sm'}}>*/}
        //             {/*                Listings that you can book without waiting for host approval.*/}
        //             {/*            </FormHelperText>*/}
        //             {/*        </Box>*/}
        //             {/*        <Switch/>*/}
        //             {/*    </FormControl>*/}
        //
        //             {/*    <FormControl orientation="horizontal">*/}
        //             {/*        <Box sx={{flex: 1, mt: 1, mr: 1}}>*/}
        //             {/*            <FormLabel sx={{typography: 'title-sm'}}>Self check-in</FormLabel>*/}
        //             {/*            <FormHelperText sx={{typography: 'body-sm'}}>*/}
        //             {/*                Easy access to the property when you arrive.*/}
        //             {/*            </FormHelperText>*/}
        //             {/*        </Box>*/}
        //             {/*        <Switch/>*/}
        //             {/*    </FormControl>*/}
        //             </DialogContent>
        //
        //             <Divider sx={{mt: 'auto'}}/>
        //             <Stack
        //                 direction="row"
        //                 justifyContent="space-between"
        //                 useFlexGap
        //                 spacing={1}
        //             >
        //                 <Button
        //                     variant="outlined"
        //                     color="neutral"
        //                     onClick={() => {
        //                         setType('');
        //                         setAmenities([]);
        //                     }}
        //                 >
        //                     Clear
        //                 </Button>
        //                 <Button onClick={onClose}>Show 165 properties</Button>
        //             </Stack>
        //         </Sheet>
        //     </Drawer>
        // </React.Fragment>
    );
}

export default ProfileDrawerContainer;