import React, {useState} from 'react';
import ProfileImage from '../shared/ProfileImage';
import TextBox from '../utils/TextBox';
import NavigationButtonGroup from './NavigationButtonGroup';
import SearchIcon from './SearchIcon';
import Grid from '@mui/joy/Grid';
import CompanyName from "../utils/CompanyName";

const NavigationBar = () => {
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

    const toggleSearchBar = () => {
        setIsSearchBarOpen(!isSearchBarOpen);
    };

    return (
        <Grid container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
        >
            <Grid container>
                <Grid>
                    <CompanyName/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container alignItems="center">
                    <NavigationButtonGroup/>
                </Grid>
                <Grid container alignItems="center">
                    <SearchIcon/>
                </Grid>
            </Grid>
            <Grid>
                <Grid container justifyContent="center">
                    <ProfileImage/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NavigationBar;
