import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import FormControl from "@mui/joy/FormControl";
import {joinWaitingList} from "../../api_calls/api";
import {useTranslation} from 'react-i18next';
import Checkbox from "@mui/joy/Checkbox";
import Button from "@mui/joy/Button";
import {CircularProgress} from "@mui/joy";


const CONSENT_KEY = 'consent_accepted';
const CONSENT_TIME_KEY = 'consent_timestamp';
const CONSENT_EXPIRY_TIME = 15 * 60 * 1000; // 15 minutes

export default function HeroLeft() {
    const {t, i18n} = useTranslation();

    const [formData, setFormData] = useState({
        firstname: "",
        surname: "",
        email: "",
        usertype: "artist",
        interestedInCommunity: "",
        publishingFrequency: "",
        publishingFrequencyOther: "",
        interestedAsEnthusiast: "",
        subscriptionAmount: "",
        subscriptionAmountOther: "",
        spendingFrequency: "",
        artistExperienceLevel: "",
        incomeGoal: "",
        comicType: "",
        additionalSuggestions: "",
        readerGenres: "",
        tipsSubscription: "",
        comicTypeOther: "",
        artistFeatures: "",
        readerFeatures: ""


    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);
    const [isConsentProcessing, setIsConsentProcessing] = useState(false);

    const checkConsentExpiry = () => {
        const consentTimestamp = localStorage.getItem(CONSENT_TIME_KEY);
        if (consentTimestamp) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - parseInt(consentTimestamp, 10);
            return timeElapsed <= CONSENT_EXPIRY_TIME;
        }
        return false;
    };

    useEffect(() => {
        // If consent is already accepted and still valid (within 15 minutes), set the state
        if (localStorage.getItem(CONSENT_KEY) && checkConsentExpiry()) {
            setIsConsentAccepted(true);
        }
    }, []);
    // Generic handleChange function
    const handleChange = (
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        name: string
    ) => {
        const {value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

// Handle select changes for specific fields
    const handleSelectChange = (event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null, value: string | null, fieldName: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value || '',
        }));
    };

    // Handle consent change (when the user accepts the consent)
    const handleConsentChange = () => {
        setIsConsentProcessing(true); // Start processing (show progress)

        // Set the consent in localStorage
        const currentTime = new Date().getTime();
        localStorage.setItem(CONSENT_KEY, 'true');
        localStorage.setItem(CONSENT_TIME_KEY, currentTime.toString());

        setTimeout(() => {
            setIsConsentAccepted(true); // Accept consent after 5 seconds
            setIsConsentProcessing(false); // End processing
        }, 5000); // Stop feedback after the delay
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isConsentAccepted) {
            alert('You must accept the consent form to submit.');
            return;
        }

        const response = await joinWaitingList({
            firstname: formData.firstname,
            surname: formData.surname,
            email: formData.email,
            usertype: formData.usertype,
            interestedInCommunity: formData.interestedInCommunity,
            publishingFrequency: formData.publishingFrequency,
            publishingFrequencyOther: formData.publishingFrequencyOther,
            interestedAsEnthusiast: formData.interestedAsEnthusiast,
            subscriptionAmount: formData.subscriptionAmount,
            subscriptionAmountOther: formData.subscriptionAmountOther,
            spendingFrequency: formData.spendingFrequency,
            artistExperienceLevel: formData.artistExperienceLevel,
            incomeGoal: formData.incomeGoal,
            comicType: formData.comicType,
            comicTypeOther: formData.comicTypeOther,
            tipsSubscription: formData.tipsSubscription,
            artistFeatures: formData.artistFeatures,
            readerGenres: formData.readerGenres,
            readerFeatures: formData.readerFeatures,
            additionalSuggestions: formData.additionalSuggestions
        });

        if (response.status === 'success') {
            setIsSubmitted(true);
        } else {
            // Handle the error message
            console.error('Submission failed:', response.message);
        }
    };

    const showPersonalInfoFields = formData.interestedInCommunity === 'yes' || formData.interestedAsEnthusiast === 'yes';

    // Add this new function to check if required fields are filled
    const isFormValid = () => {
        if (!isConsentAccepted) return false;

        // Basic required fields for all users
        if (!formData.usertype) return false;

        // Artist-specific required fields
        if (formData.usertype === 'artist') {
            if (!formData.interestedInCommunity ||
                !formData.publishingFrequency ||
                !formData.artistExperienceLevel ||
                !formData.tipsSubscription) {
                return false;
            }
            // Check for "other" publishing frequency
            if (formData.publishingFrequency === 'other' && !formData.publishingFrequencyOther) {
                return false;
            }
        }

        // Reader-specific required fields
        if (formData.usertype === 'reader') {
            if (!formData.interestedAsEnthusiast ||
                !formData.readerGenres) {
                return false;
            }
            // Additional validation for enthusiast readers
            if (formData.interestedAsEnthusiast === 'yes') {
                if (!formData.spendingFrequency ||
                    !formData.subscriptionAmount) {
                    return false;
                }
                // Check for "others" subscription amount
                if (formData.subscriptionAmount === 'others' && !formData.subscriptionAmountOther) {
                    return false;
                }
            }
        }

        // Check personal info fields if required
        if (showPersonalInfoFields) {
            if (!formData.firstname || !formData.surname || !formData.email) {
                return false;
            }
        }

        return true;
    };

    if (isSubmitted) {
        return (
            <TwoSidedLayout reversed>
                <Chip size="lg" variant="outlined" color="neutral">
                    {t('thank_you_message')}
                </Chip>
                <Typography
                    level="h1"
                    fontWeight="xl"
                    fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
                >
                    {t('registration_confirmation_message')}
                </Typography>
            </TwoSidedLayout>
        );
    }

    return (
        <TwoSidedLayout reversed>
            {/* Conditionally render consent form if not accepted */}
            <Chip size="lg" variant="outlined" color="neutral">
                {t('header_message')}
            </Chip>
            <Typography
                level="h1"
                fontWeight="xl"
                fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
            >
                {t('invitation_message')}
            </Typography>
            <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
                {t('introduction')}
            </Typography>
            {!isConsentAccepted && (
                <Box sx={{mt: 3, mb: 3, padding: 2, border: '1px solid grey', borderRadius: 2}}>
                    <Typography fontSize="md" fontWeight="bold">
                        {t('consent_title')}
                    </Typography>
                    <Typography fontSize="sm">
                        <strong>{t('consent_text.background')}</strong>
                        {t('consent_text.procedures')}
                    </Typography>
                    <Typography fontSize="sm" sx={{mt: 2}}>
                        <strong>{t('consent_text.voluntary_participation')}</strong>
                        {t('consent_text.confidentiality')}
                    </Typography>
                    <Typography fontSize="sm" sx={{mt: 2}}>
                        <strong>{t('consent_text.risks')}</strong>
                        {t('consent_text.rewards')}
                    </Typography>
                    <Typography fontSize="sm" sx={{mt: 2}}>
                        <strong>{t('consent_text.costs')}</strong>
                        {t('consent_text.benefits')}
                    </Typography>
                    <Typography fontSize="sm" sx={{mt: 2}}>
                        <strong>{t('consent_text.consent')}</strong>
                    </Typography>
                    <FormControl sx={{mt: 2}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {!isConsentProcessing ? (
                                <>
                                    <Checkbox
                                        checked={isConsentAccepted}
                                        onChange={handleConsentChange}
                                        disabled={isConsentProcessing} // Disable during processing
                                    />
                                    <FormLabel sx={{marginLeft: 1}}>
                                        {t('consent_checkbox_label')}
                                    </FormLabel>
                                </>
                            ) : (
                                <Button
                                    startDecorator={<CircularProgress variant="solid"/>}
                                    sx={{display: 'flex', gap: 2, alignItems: 'center', marginLeft: 2}}
                                    disabled
                                >
                                    Loading…
                                </Button>
                            )}
                        </Box>
                    </FormControl>
                </Box>
            )}

            {/* Conditional rendering of survey questions after consent */}
            {isConsentAccepted && (
                <>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            my: 2,
                            alignSelf: 'stretch',
                            flexBasis: '80%',
                        }}
                    >
                        <FormControl
                            required
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <FormLabel sx={{whiteSpace: 'nowrap'}}>{t('user_type_label')}</FormLabel>
                            <Select
                                id="usertype"
                                name="usertype"
                                value={formData.usertype}
                                onChange={(e, value) => handleSelectChange(e, value, "usertype")}
                                sx={{flexGrow: 1}}
                            >
                                <Option value="artist">{t('user_type_artist')}</Option>
                                <Option value="reader">{t('user_type_reader')}</Option>
                            </Select>
                        </FormControl>

                        {/* Questions for Artists */}
                        {formData.usertype === 'artist' && (
                            <>
                                <FormControl required>
                                    <FormLabel>{t('artist_community_question')}</FormLabel>
                                    <Select
                                        name="interestedInCommunity"
                                        value={formData.interestedInCommunity}
                                        onChange={(e, value) => handleSelectChange(e, value, "interestedInCommunity")}
                                    >
                                        <Option value="yes">{t('yes')}</Option>
                                        <Option value="no">{t('no')}</Option>
                                    </Select>
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>{t('publishing_frequency_label')}</FormLabel>
                                    <Select
                                        name="publishingFrequency"
                                        value={formData.publishingFrequency}
                                        onChange={(e, value) => handleSelectChange(e, value, "publishingFrequency")}
                                    >
                                        <Option value="weekly">{t('publishing_frequency_weekly')}</Option>
                                        <Option value="monthly">{t('publishing_frequency_monthly')}</Option>
                                        <Option value="other">{t('publishing_frequency_other')}</Option>
                                    </Select>
                                    {formData.publishingFrequency === 'other' && (
                                        <Input
                                            name="publishingFrequencyOther"
                                            value={formData.publishingFrequencyOther}
                                            onChange={(e) => handleChange(e as any, "publishingFrequencyOther")}
                                        />
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>{t('income_goal_label')}</FormLabel>
                                    <Select
                                        name="incomeGoal"
                                        value={formData.incomeGoal}
                                        onChange={(e, value: string | null) => handleSelectChange(e, value, "incomeGoal")}
                                    >
                                        <Option value="<100">{t('income_goal_100')}</Option>
                                        <Option value="100-500">{t('income_goal_100_500')}</Option>
                                        <Option value="500-1000">{t('income_goal_500_1000')}</Option>
                                        <Option value=">1000">{t('income_goal_1000')}</Option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>{t('comic_type_label')}</FormLabel>
                                    <Select
                                        name="comicType"
                                        value={formData.comicType}
                                        onChange={(e, value: string | null) => handleSelectChange(e, value, "comicType")}
                                    >
                                        <Option value="manga">{t('comic_type_manga')}</Option>
                                        <Option value="graphicNovels">{t('comic_type_graphic_novels')}</Option>
                                        <Option value="webcomics">{t('comic_type_webcomics')}</Option>
                                        <Option value="others">{t('comic_type_others')}</Option>
                                    </Select>
                                    {formData.comicType === 'others' && (
                                        <Input
                                            name="comicTypeOther"
                                            type="text"
                                            placeholder={t('comic_type_specify')}
                                            value={formData.comicTypeOther}
                                            onChange={(e) => handleChange(e as any, "comicTypeOther")}
                                        />
                                    )}
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>{t('monetization_interest_label')}</FormLabel>
                                    <Select
                                        name="tipsSubscription"
                                        value={formData.tipsSubscription}
                                        onChange={(e, value: string | null) => handleSelectChange(e, value, "tipsSubscription")}
                                    >
                                        <Option value="yes">{t('yes')}</Option>
                                        <Option value="no">{t('no')}</Option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>{t('artist_features_label')}</FormLabel>
                                    <Input
                                        name="artistFeatures"
                                        value={formData.artistFeatures}
                                        onChange={(e) => handleChange(e as any, "artistFeatures")}
                                    />
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>{t('artist_experience_label')}</FormLabel>
                                    <Select
                                        name="artistExperienceLevel"
                                        value={formData.artistExperienceLevel}
                                        onChange={(e, value) => handleSelectChange(e, value, "artistExperienceLevel")}
                                    >
                                        <Option value="beginner">{t('experience_beginner')}</Option>
                                        <Option value="intermediate">{t('experience_intermediate')}</Option>
                                        <Option value="expert">{t('experience_expert')}</Option>
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {/* Questions for Readers */}
                        {formData.usertype === 'reader' && (
                            <>
                                <FormControl required>
                                    <FormLabel>{t('reader_interest_question')}</FormLabel>
                                    <Select
                                        name="interestedAsEnthusiast"
                                        value={formData.interestedAsEnthusiast}
                                        onChange={(e, value) => handleSelectChange(e, value, "interestedAsEnthusiast")}
                                    >
                                        <Option value="yes">{t('yes')}</Option>
                                        <Option value="no">{t('no')}</Option>
                                    </Select>
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>{t('reader_genres_label')}</FormLabel>
                                    <Select
                                        name="readerGenres"
                                        value={formData.readerGenres}
                                        onChange={(e, value) => handleSelectChange(e, value, "readerGenres")}
                                    >
                                        <Option value="action">{t('genre_action')}</Option>
                                        <Option value="Adventure">{t('genre_adventure')}</Option>
                                        <Option value="comedy">{t('genre_comedy')}</Option>
                                        <Option value="drama">{t('genre_drama')}</Option>
                                        <Option value="fantasy">{t('genre_fantasy')}</Option>
                                        <Option value="horror">{t('genre_horror')}</Option>
                                        <Option value="romance">{t('genre_romance')}</Option>
                                        <Option value="sci-fi">{t('genre_scifi')}</Option>
                                        <Option value="other">{t('genre_other')}</Option>
                                    </Select>
                                    {formData.readerGenres === 'other' && (
                                        <Input
                                            name="readerGenres"
                                            value={formData.readerGenres}
                                            onChange={(e) => handleChange(e as any, "readerGenres")}
                                        />
                                    )}
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>{t('reader_support_question')}</FormLabel>
                                    <Select
                                        name="interestedAsEnthusiast"
                                        value={formData.interestedAsEnthusiast}
                                        onChange={(e, value) => handleSelectChange(e, value, "interestedAsEnthusiast")}
                                    >
                                        <Option value="yes">{t('yes')}</Option>
                                        <Option value="no">{t('no')}</Option>
                                    </Select>
                                </FormControl>

                                <FormControl required>
                                    <FormLabel>{t('reader_subscription_question')}</FormLabel>
                                    <Select
                                        name="interestedAsEnthusiast"
                                        value={formData.interestedAsEnthusiast}
                                        onChange={(e, value) => handleSelectChange(e, value, "interestedAsEnthusiast")}
                                    >
                                        <Option value="yes">{t('yes')}</Option>
                                        <Option value="no">{t('no')}</Option>
                                    </Select>
                                </FormControl>

                                {formData.interestedAsEnthusiast === 'yes' && (
                                    <>
                                        <FormControl required>
                                            <FormLabel>{t('reading_frequency_label')}</FormLabel>
                                            <Select
                                                name="spendingFrequency"
                                                value={formData.spendingFrequency}
                                                onChange={(e, value) => handleSelectChange(e, value, "spendingFrequency")}
                                            >
                                                <Option value="weekly">{t('reading_frequency_weekly')}</Option>
                                                <Option value="monthly">{t('reading_frequency_monthly')}</Option>
                                            </Select>
                                        </FormControl>

                                        <FormControl required>
                                            <FormLabel>{t('subscription_amount_label')}</FormLabel>
                                            <Select
                                                name="subscriptionAmount"
                                                value={formData.subscriptionAmount}
                                                onChange={(e, value) => handleSelectChange(e, value, "subscriptionAmount")}
                                            >
                                                <Option value="1-5">{t('subscription_1_5')}</Option>
                                                <Option value="6-10">{t('subscription_6_10')}</Option>
                                                <Option value="11-20">{t('subscription_11_20')}</Option>
                                                <Option value="others">{t('subscription_others')}</Option>
                                            </Select>
                                            {formData.subscriptionAmount === 'others' && (
                                                <Input
                                                    name="subscriptionAmountOther"
                                                    value={formData.subscriptionAmountOther}
                                                    onChange={(e) => handleChange(e as any, "subscriptionAmountOther")}
                                                    placeholder={t('subscription_amount_specify')}
                                                />
                                            )}
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>{t('reader_features_label')}</FormLabel>
                                            <Input
                                                name="readerFeatures"
                                                value={formData.readerFeatures}
                                                onChange={(e) => handleChange(e as any, "readerFeatures")}
                                            />
                                        </FormControl>

                                    </>
                                )}
                            </>
                        )}

                        {/* Common Questions */}
                        <FormControl>
                            <FormLabel>{t('additional_suggestions_label')}</FormLabel>
                            <Input
                                name="additionalSuggestions"
                                value={formData.additionalSuggestions}
                                onChange={(e) => handleChange(e as any, "additionalSuggestions")}
                            />
                        </FormControl>

                        {/* Personal Info Fields */}
                        {showPersonalInfoFields && (
                            <>
                                <Input
                                    name="firstname"
                                    value={formData.firstname}
                                    placeholder="First Name"
                                    onChange={(e) => handleChange(e as any, "firstname")}
                                />
                                <Input
                                    name="surname"
                                    value={formData.surname}
                                    placeholder="Surname"
                                    onChange={(e) => handleChange(e as any, "surname")}
                                />
                                <Input
                                    name="email"
                                    value={formData.email}
                                    placeholder="Email"
                                    onChange={(e) => handleChange(e as any, "email")}
                                />
                            </>
                        )}

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                            <IconButton
                                type="submit"
                                disabled={!isFormValid()}
                                sx={{
                                    mt: 2,
                                    bgcolor: isFormValid() ? 'primary.main' : 'grey.300',
                                    '&:hover': {
                                        bgcolor: isFormValid() ? 'primary.dark' : 'grey.300',
                                    },
                                }}
                            >
                                <ArrowForward/>
                            </IconButton>
                        </Box>
                    </Box>
                </>
            )}
        </TwoSidedLayout>
    );
}