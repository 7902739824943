import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
// import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import ChapterViewPage from "./pages/ChapterViewPage";
import ChapterView from "./components/containers/chapter/ChapterView";
import UploadChapterPage from "./pages/UploadChapterPage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import BookCreationPage from "./pages/BookCreationPage";
import ChapterCreationPage from "./pages/ChapterCreationPage";
import ProfilePage from "./pages/ProfilePage";
import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
// import AnalyticsPage from "./pages/AnalyticsPage";
// import ArtistHomePage from "./pages/ArtistHomePage";
// import ArtistMangaUploadPage from "./pages/ArtistMangaUploadPage";
// import ArtistMangaAnalyticsPage from "./pages/ArtistMangaAnalyticsPage";
// import SettingsPage from "./pages/SettingsPage";
// import MangaReaderPage from "./pages/ReaderHomePage";
const Router = () => {
    // While in early development, the page being worked on
    // will be the home page with the route / to make
    // development faster and easier
    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<LandingPage/>}/>
                <Route path="/signin" element={<SignIn />}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/home" element={<HomePage />}/>
                <Route path="/chapter" element={<ChapterViewPage/>}/>
                <Route path="/render" element={<ChapterView/>}/>
                <Route path="/create-book" element={<BookCreationPage/>}/>
                <Route path="/create-chapter" element={<ChapterCreationPage/>}/>
                <Route path="/manga" element={<UploadChapterPage/>}/>
                <Route path="/profile" element={<ProfilePage/>}/>
                <Route path="/search" element={<SearchPage/>}/>
                {/*<Route path="/analytics" element=<AnalyticsPage/>/>*/}
                {/*<Route path="/artist-home" element=<ArtistHomePage/>/>*/}
                {/*<Route path="/manga-upload/:uuid" element=<ArtistMangaUploadPage/>/>*/}
                {/*<Route path="/analytics/:uuid" element=<ArtistMangaAnalyticsPage/>/>*/}
                {/*<Route path="/settings" element=<SettingsPage/>/>*/}
                {/*<Route path="/manga-read/:uuid" element=<MangaReaderPage/>/>*/}
                {/*<Route path="/artist-manga-upload/:uuid" component={ArtistMangaUploadPage} />*/}
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Router
