import * as React from 'react';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Typography from '@mui/joy/Typography';
import Grid from "@mui/joy/Grid";

interface ContainerPageNavigationProps {
    page: number;
    totalPages: number;
    perPage: number;
    onPageChange: (newPage: number) => void;
    text: string;
}

export default function ContainerPageNavigation({
                                                    page,
                                                    totalPages,
                                                    perPage,
                                                    onPageChange,
                                                    text,
                                                }: ContainerPageNavigationProps) {
    const handlePrevPage = () => {
        if (page > 1) {
            onPageChange(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            onPageChange(page + 1);
        }
    };

    return (
        <Grid
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                margin: '12px',
                ml: '-16px',
                mr: '-16px',
                pl: '16px',
                pr: '16px',
                // border: '1px solid #ccc',
                // borderRadius: '4px',
                width: '80%', // Make the PageNavigation as wide as the container
            }}
        >
            <Typography
                sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: 'blue', // Set the color as needed
                    marginRight: 'auto', // Push the text to the left
                }}
            >
                {text}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                }}
            >
                <NavigateBeforeIcon
                    onClick={handlePrevPage}
                    style={{cursor: 'pointer', color: page === 1 ? 'gray' : 'blue'}}
                />
                <NavigateNextIcon
                    onClick={handleNextPage}
                    style={{
                        cursor: 'pointer',
                        color: page === totalPages ? 'gray' : 'blue',
                    }}
                />
            </Box>
        </Grid>
    );
}
