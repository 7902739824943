import React from 'react';
import {
    Card,
    Box,
    Typography,
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
    IconButton,
    CardOverflow,
    AspectRatio,
} from '@mui/joy';
import PropTypes from 'prop-types';
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import {Chapter} from "../../models/Chapter";

// Define the chapter interface

interface ChapterCardProps {
    chapter: Chapter;
}

const ChapterCard: React.FC<ChapterCardProps> = ({
    chapter: {
        chapter_id,
        chapter_uuid,
        chapter_number,
        title,
        year,
        description,
        coverImage,
        rating,
        readings,
    },
}) => (
    <Card variant="outlined" size="sm">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
                <Typography level="title-md">{title}</Typography>
                {/*<Typography level="body-sm">Year: {year}</Typography>*/}
                {/*<Typography level="body-sm">Language: {language}</Typography>*/}
                {/*<Typography level="body-sm">Author: {author}</Typography>*/}
                <Typography level="body-sm">{chapter_number}</Typography>
                {/*<Typography level="body-sm">Country: {country}</Typography>*/}
                {/*<Typography level="body-sm">Rating: {rating}</Typography>*/}
                {/*<Typography level="body-sm">Readings: {readings}</Typography>*/}
            </Box>
            <Dropdown>
                <MenuButton
                    variant="plain"
                    size="sm"
                    sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
                >
                    <IconButton component="span" variant="plain" color="neutral" size="sm">
                        <MoreVertRoundedIcon />
                    </IconButton>
                </MenuButton>
                <Menu
                    placement="bottom-end"
                    size="sm"
                    sx={{
                        zIndex: '99999',
                        p: 1,
                        gap: 1,
                        '--ListItem-radius': 'var(--joy-radius-sm)',
                    }}
                >
                    <MenuItem>
                        <EditRoundedIcon />
                        Edit chapter
                    </MenuItem>
                    <MenuItem>
                        <ShareRoundedIcon />
                        Share chapter
                    </MenuItem>
                    <MenuItem sx={{ textColor: 'danger.500' }}>
                        <DeleteRoundedIcon />
                        Delete chapter
                    </MenuItem>
                </Menu>
            </Dropdown>
        </Box>
        <CardOverflow
            sx={{
                borderBottom: '1px solid',
                borderTop: '1px solid',
                borderColor: 'neutral.outlinedBorder',
            }}
        >
            <AspectRatio ratio="16/9" color="primary" sx={{ borderRadius: 0 }}>
                <img alt={title} src={coverImage} />
            </AspectRatio>
        </CardOverflow>
        <Typography level="body-sm">{year}</Typography>
        <Typography level="body-xs">{description}</Typography>
    </Card>
);

ChapterCard.propTypes = {
    chapter: PropTypes.shape({
        chapter_id: PropTypes.number.isRequired,
        chapter_uuid: PropTypes.string.isRequired,
        chapter_number: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        coverImage: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        readings: PropTypes.number.isRequired,
    }).isRequired,
};

export default ChapterCard;
