import React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import countries from '../../models/Countries';

// Define the type for Country
export interface Country {
    label: string;
    code: string;
    phone: string;
}

interface CountrySelectorProps {
    value: Country | null;
    onChange: (event: React.SyntheticEvent, value: Country | null) => void;
    placeholder?: string;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ value, onChange, placeholder = "Choose a country" }) => {
    return (
        <Autocomplete
            id="country-select"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            slotProps={{
                input: {
                    autoComplete: 'new-password', // disable autocomplete and autofill
                },
            }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <AutocompleteOption {...props}>
                    <ListItemDecorator>
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                        />
                    </ListItemDecorator>
                    <ListItemContent sx={{ fontSize: 'sm' }}>
                        {option.label}
                        <Typography level="body-xs">
                            ({option.code}) +{option.phone}
                        </Typography>
                    </ListItemContent>
                </AutocompleteOption>
            )}
            sx={{ width: '100%' }}
        />
    );
};

export default CountrySelector;
