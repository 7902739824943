import * as React from 'react';
import Checkbox from '@mui/joy/Checkbox';

const ParentCheckbox: React.FC<{
    label: string;
    checked: boolean;
    indeterminate: boolean;
    onClick: () => void;
}> = ({label, checked, indeterminate, onClick}) => (
    <>
        <Checkbox
            label={label}
            checked={checked}
            indeterminate={indeterminate}
            onChange={() => {
            }}
            onClick={onClick}
        />
    </>
);

export default ParentCheckbox;
