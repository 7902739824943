import React from 'react';
import '../../styles/Footer.css'; // Create a CSS file for styling

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <div className="footer">
      <div className="copyright">© {currentYear} PanComix</div>
      <div className="contact">Contact Us</div>
    </div>
  );
};

export default Footer;
