// Define the Book interface
export interface Book {
    book_id: number;
    book_uuid: string;
    title: string;
    year: number;
    language: string;
    author: string;
    description: string;
    coverImage: string;
    status: string;
    genre: string;
    country: string;
    rating: number;
    readings: number;
}

// Function to format numbers with 'k', 'M', 'B' suffixes
export function formatReadings(number: number): string {
    if (number < 1000) {
        return number.toString();
    } else if (number < 1000000) {
        return (number / 1000).toFixed(1) + 'k';
    } else if (number < 1000000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else {
        return (number / 1000000000).toFixed(1) + 'B';
    }
}

export function formatStatus(status: string): string {
    if (status === "ongoing") {
        return "ep+"
    }else if (status === "completed") {
        return "end"
    } else {
        return ""
    }
}

export function formatLanguage(language: string): string {
    const languageLookup: { [key: string]: string } = {
        'French': 'Fr',
        'English': 'En'
    };

    return languageLookup[language] || language;
}